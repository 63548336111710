import { App, URLOpenListenerEvent } from '@capacitor/app';
import { LoggerService } from './core/logger/logger.service';
import { environment } from './../environments/environment';
import packageJson from './../../package.json';

import { UtilsService } from './core/utils/utils.service';
import { AuthService } from './auth/auth.service';
import { Component, NgZone, OnDestroy } from '@angular/core';
import { Storage } from '@ionic/storage-angular';

import { AlertController, Platform, ToastController } from '@ionic/angular';
import { NavigationEnd, Router } from '@angular/router';

import { StatusBar, Style } from '@capacitor/status-bar';
import { SplashScreen } from '@capacitor/splash-screen';

import { debounceTime, filter, take, takeWhile } from 'rxjs/operators';
import moment from 'moment/moment';
import { SyncService } from './core/sync/sync.service';
import * as LiveUpdates from '@capacitor/live-updates';
import {
    AppUpdate,
    AppUpdateAvailability,
    AppUpdateResultCode,
    FlexibleUpdateInstallStatus,
    FlexibleUpdateState,
} from '@capawesome/capacitor-app-update';
import { Capacitor } from '@capacitor/core';
import { AuthInfo } from './auth/auth-info.interface';

const AUTH_ENDPOINT_URL_PATH = '/auth';
const PUBLIC_CODE_ENDPOINT_URL_PATH = '/code';

interface DpBroadcastMessage {
    msg: string | undefined;
    action: BroadcastAction;
    data: any;
}

enum BroadcastAction {
    TabOpened = 'dp-tab-opened',
    Logout = 'dp-logout',
    AccountSwitch = 'dp-account-switched',
    RequestAuthState = 'dp-request-auth-state',
    RespondAuthState = 'dp-respond-auth-state',
}

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnDestroy {
    isStatusBarLight = true;
    startupLoaderVisible = true;

    unlockDeviceLoading = false;
    nextUnlockPossibleString = '';

    private waitingForUpdate = false;
    private channel: BroadcastChannel;
    private broadcastRequestTimeout;
    private broadcastResponseTimeout;

    constructor(
        public authSvc: AuthService,
        public utilSvc: UtilsService,
        private platform: Platform,
        private router: Router,
        private toastCtrl: ToastController,
        private alertCtrl: AlertController,
        private logger: LoggerService,
        private storage: Storage,
        private syncSvc: SyncService,
        private zone: NgZone
    ) {
        this.initializeApp();
    }

    initializeApp(): void {
        App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
            this.zone.run(() => {
                // Example url: https://app.dokupit.com/settings
                // slug = /settings
                const slug = event.url.split(environment.DEEPLINK_DOMAIN).pop();
                if (slug) {
                    if (event.url.includes('#code')) {
                        // handle microsoft sso (msal) authentication via deeplink from microsoft auth webpage
                        this.authSvc.handleNativeMsalAuthRedirectResult(slug.split('#')[1]);
                    } else {
                        // default slug navigation
                        this.router.navigateByUrl(slug);
                    }
                }
                // If no match, do nothing - let regular routing
                // logic take over
            });
        });

        this.platform.ready().then(() => {
            this.logger.log('[APP] Platform ready');

            if (this.platform.is('hybrid')) {
                StatusBar.setStyle({
                    style: this.isStatusBarLight ? Style.Dark : Style.Light,
                });

                // keep track of our app being started for automatic reloads on too long sessions
                // meaning multiple days
                this.persistAppStartupTime();
            }
            if (this.platform.is('android')) {
                // StatusBar.setOverlaysWebView({ overlay: true }); // requires app to respect additional padding in header to move elements below the status bar

                // Beware to use a 6 digit hex color or else this will fail
                StatusBar.setBackgroundColor({ color: '#000000' });
            }
            SplashScreen.hide();

            App.addListener('appRestoredResult', (data) => {
                this.logger.warn('[APP] restored with result', data);
            });

            // hide startup loader if we are on auth page (we need to wait till first navigation has finished)
            this.router.events
                .pipe(
                    filter((ev: any) => ev instanceof NavigationEnd),
                    takeWhile(() => this.startupLoaderVisible === true)
                )
                .subscribe((ev: NavigationEnd) => {
                    if (ev.url.includes(AUTH_ENDPOINT_URL_PATH) || ev.url.includes(PUBLIC_CODE_ENDPOINT_URL_PATH)) {
                        // we are on auth page, so we will not receive a appready event, hide the loader
                        this.startupLoaderVisible = false;
                    }
                });

            // check if multiple tabs are open
            if (!this.utilSvc.isNative) {
                this.channel = new BroadcastChannel('dp-app');
                let lastLoginState = false;

                // we always send a message to other listeners
                this.channel.postMessage({
                    action: BroadcastAction.TabOpened,
                });

                // send logout message to immediately logout other tabs as well
                let loginStateSub = this.authSvc.loginStatusChanged$.subscribe((state) => {
                    if (!!state === false && lastLoginState) {
                        this.channel.postMessage({
                            action: BroadcastAction.Logout,
                        });
                    }
                    lastLoginState = !!state;
                });

                // send tab open message as soon as login data is available
                this.authSvc.authInfoChanged$
                    .pipe(
                        filter((x: AuthInfo) => x !== undefined),
                        take(1)
                    )
                    .subscribe((auth: AuthInfo) => {
                        // we always send a message to other listeners
                        this.channel.postMessage({
                            action: BroadcastAction.AccountSwitch,
                            data: {
                                user: auth.userData._id,
                                company: auth.companyData._id,
                            },
                        });
                    });

                this.channel.addEventListener('message', (msg: MessageEvent<DpBroadcastMessage>) => {
                    this.logger.error('[APP] message received', msg);
                    if (msg.data.action === BroadcastAction.RequestAuthState) {
                        this.logger.log('[APP] Other tab requests update');
                        // other tabs request auth state, respond if we are logged in or not
                        this.channel.postMessage({
                            action: BroadcastAction.RespondAuthState,
                            data: {
                                loggedIn: this.authSvc.isLoggedIn,
                            },
                        });
                    }
                    if (msg.data.action === BroadcastAction.RespondAuthState) {
                        clearTimeout(this.broadcastResponseTimeout);
                        if (!msg.data.data.loggedIn) {
                            this.logger.log('[APP] Other tab responded', msg.data);
                            this.syncSvc.setSyncBlock(false);
                        } else {
                            // keep sync block - we do this again cause what if 1 of 4 tabs is authenticated
                            this.syncSvc.setSyncBlock(true);
                        }
                    }
                    if (msg.data.action === BroadcastAction.TabOpened) {
                        // other tab opened - for good measure we block the sync processing until we know the session is the same
                        this.logger.log('[APP] dokupit opened in another tab - will block sync for time being');
                        this.syncSvc.setSyncBlock(true);

                        this.broadcastRequestTimeout = setTimeout(() => {
                            this.logger.log('[APP] no messages on channel yet - we request a status update');
                            // request confirmation of other contexts - if other tabs are open and those are not
                            // authenticated leads us to the problem where this tab won't be able to unlock the sync block
                            // until we reload the tab
                            this.channel.postMessage({
                                action: BroadcastAction.RequestAuthState,
                            });
                            this.broadcastResponseTimeout = setTimeout((_) => {
                                this.logger.log('[APP] no response from other tabs - we remove the sync block');
                                this.syncSvc.setSyncBlock(false);
                            }, 3000);
                        }, 14000);
                    }
                    if (msg.data.action === BroadcastAction.AccountSwitch) {
                        clearTimeout(this.broadcastRequestTimeout);
                        if (
                            msg.data.data.user != this.authSvc.authInfo?.userData._id ||
                            msg.data.data.company != this.authSvc.authInfo?.companyData._id
                        ) {
                            // if we receive a message from another tab that dp is opened we need to lock the app in this tab
                            this.logger.log('[APP] dokupit opened in another tab with other user - will lock this tab');

                            this.utilSvc.toggleTabLock();
                            this.router.navigate(['/tab-locked'], { replaceUrl: true });
                        } else {
                            this.logger.log('[APP] dokupit opened the same session in another tab - removing sync block');
                            // unlock sync again as we have the same session
                            this.syncSvc.setSyncBlock(false);
                        }
                    }
                    if (msg.data.action === BroadcastAction.Logout) {
                        clearTimeout(this.broadcastRequestTimeout);
                        // other tab logged out - block current sync, show page loader and then reload site
                        // Doing deletions or clearings in this tab is not necessary as the other one already clears all data
                        this.logger.log('[APP] dokupit logged out - will reload tab');
                        this.syncSvc.setSyncBlock(true);
                        loginStateSub.unsubscribe();
                        this.utilSvc.displayPageBlockingLoader(true);

                        // just to better show the page block loader before reloading
                        setTimeout((_) => {
                            window.location.reload();
                        }, 1400);
                    }
                });
            }

            this.checkUpdates();
        });

        // wait until app is ready (auth done) to hide startup loader
        this.authSvc.appReady$.pipe(takeWhile((appReady) => this.startupLoaderVisible === true)).subscribe((appReady) => {
            if (appReady) {
                this.startupLoaderVisible = false;
            }
        });

        // Handle app resumes like transitions from standby or background
        this.platform.resume.subscribe(async () => {
            // ... and do an update check as soon as possible
            await this.checkUpdates();

            if (this.utilSvc.isNative) {
                let startTime = await this.storage.get('dm_app_startup_time');
                if (startTime != null && startTime < Date.now() - 7 * 24 * 60 * 60 * 1000) {
                    this.logger.warn('[APP] App running for too long. Will reload...', [startTime, Date.now()]);
                    try {
                        await this.storage.set('dm_app_startup_time', Date.now());
                        window.location.reload();
                    } catch (resetTimeErr) {
                        this.logger.error('[APP] Failed resetting app start time');
                    }
                }
            }
        });
    }

    ngOnDestroy(): void {
        this.channel?.removeAllListeners('message');
    }

    private async persistAppStartupTime() {
        try {
            await this.storage.create();
            await this.storage.set('dm_app_startup_time', Date.now());
            this.logger.log('[APP] Saved current app startup time');
        } catch (err) {
            this.logger.error('[APP] Could not save startup time of app', err);
            this.utilSvc.sentryCaptureException(err);
        }
    }

    /**
     * Used to unlock current device
     */
    async unlockDevice() {
        try {
            this.logger.log('[APP] trying to unlock device');
            this.unlockDeviceLoading = true;
            let unlockInfo = await this.storage.get('dm_app_unlock_info');

            // calculate next possible unlock time based on tries today
            let unlockPossible = true;
            let unlockTriesToday = 0;
            if (unlockInfo != null) {
                // unlock info is set, so we calculate if an unlock is already possible based on last unlock time
                if (moment(unlockInfo.lastUnlockTimestamp).isSame(moment(), 'day')) {
                    unlockTriesToday = unlockInfo.unlockTries;
                } else {
                    unlockTriesToday = 0;
                }
                let unlockMinutesToAdd = 5;
                let counter = 0;
                while (counter < unlockTriesToday) {
                    unlockMinutesToAdd = unlockMinutesToAdd * 2;
                    counter++;
                }
                let nextPossibleUnlockMoment = moment(unlockInfo.lastUnlockTimestamp).add(unlockMinutesToAdd, 'minutes');
                if (moment().isSameOrAfter(nextPossibleUnlockMoment)) {
                    unlockPossible = true;
                } else {
                    unlockPossible = false;
                    if (moment(nextPossibleUnlockMoment).isAfter(moment(), 'day')) {
                        this.nextUnlockPossibleString = 'morgen';
                    } else {
                        this.nextUnlockPossibleString = 'um ' + moment(nextPossibleUnlockMoment).format('HH:mm');
                    }
                }
            }

            if (unlockPossible) {
                await this.authSvc.startLoginWithTokens(true);
                this.logger.log('[APP] unlocked device');
                this.unlockDeviceLoading = false;
                this.authSvc.setDeviceLock(false);

                this.nextUnlockPossibleString = '';
                unlockTriesToday++;
                await this.storage.set('dm_app_unlock_info', {
                    lastUnlockTimestamp: Date.now(),
                    unlockTries: unlockTriesToday,
                });
            } else {
                this.logger.log('[APP] cannot unlock device yet', unlockInfo);
                this.unlockDeviceLoading = false;
            }
        } catch (unlockErr) {
            this.logger.warn('[APP] could not unlock device', unlockErr);
            this.unlockDeviceLoading = false;
        }
    }

    /**
     * Used to reload page when tab lock is active
     */
    async reloadTab() {
        window.location.reload();
    }

    /**
     * Checks current app with Ionic Appflow and the app stores for updates and automticaly applies them when available
     * @returns void
     */
    private async checkUpdates() {
        if (this.waitingForUpdate) {
            this.logger.log('[APP] Skipping (live) update check due to already in process');
            return;
        }

        // test for native updates first
        if (this.utilSvc.isNative && !environment.debug) {
            this.logger.log('[APP] Checking for native store updates first');
            try {
                await this.checkNativeAppStoreUpdates();
            } catch (ex) {
                this.logger.error('[APP] Failed on checking for native updates!', ex);
            }
        }

        if (this.waitingForUpdate) {
            this.logger.log('[APP] Skipping live update check as now there is an update in progress');
            return;
        }

        if (environment.blockLiveUpdate || environment.debug) {
            this.logger.warn('[APP] Skipping (live) update due to block');
            // we done here
            return;
        }

        // Appflow updates are only available in hybrid installations
        if (!this.utilSvc.isNative) {
            this.logger.log('[APP] Skipping live update due to not being in compatible environment');
            return;
        }

        if (!packageJson.isBeta) {
            await LiveUpdates.setConfig({ channel: 'Production' });
        } else {
            await LiveUpdates.setConfig({ channel: 'Beta' });
        }

        this.waitingForUpdate = true;
        const updateRes = await LiveUpdates.sync();
        if (updateRes.activeApplicationPathChanged) {
            let toast = await this.toastCtrl.create({
                header: 'Update verfügbar',
                message: `Das Update wird im Hintergrund installiert und die App anschließend neu gestartet`,
                duration: 6000,
                position: 'bottom',
                cssClass: 'dp-toast',
                buttons: [
                    {
                        side: 'end',
                        // icon: "close-outline",
                        text: 'OK',
                        role: 'cancel',
                        handler: () => {
                            this.logger.log('[APP] Update toast dismissed');
                        },
                    },
                ],
            });

            await toast.present();
            this.logger.log('[APP] Found update. Applying it', updateRes);

            // wait for as long as the toast shows to give the use some time to understand what is happening and then wait for sync to complete before reloading
            setTimeout((_) => {
                this.syncSvc.setSyncBlock(true);
                this.syncSvc.syncRunning$
                    .pipe(
                        debounceTime(300),
                        takeWhile((status) => status !== false)
                    )
                    .subscribe({
                        complete: async () => {
                            this.waitingForUpdate = false;
                            this.logger.warn('[APP] sync stopped. now we can update');

                            try {
                                await LiveUpdates.reload();
                            } catch (luEx) {
                                this.logger.error(luEx);
                                this.syncSvc.setSyncBlock(false);
                                this.utilSvc.sentryCaptureException(luEx);
                            }
                        },
                    });
            }, 6000);
        } else {
            this.waitingForUpdate = false;
        }
    }

    /**
     * Checks for and tries to apply native app updates either via InApp Update on Android if possible or linking to the platform's app store
     * @private
     */
    private async checkNativeAppStoreUpdates() {
        let info = await AppUpdate.getAppUpdateInfo();

        if (info.updateAvailability !== AppUpdateAvailability.UPDATE_AVAILABLE) {
            this.logger.warn('[APP] No store update available', info.updateAvailability);
            return;
        }

        this.logger.warn('[APP] available store update', [info.availableVersionName, info.availableVersionCode, Capacitor.getPlatform()]);

        if (Capacitor.getPlatform() == 'android') {
            // Android - first try immediate if prio is high enough, then try flexbile update otherwise just link to the play store
            // AppUpdate priority 0-5 -> see https://developers.google.com/android-publisher/api-ref/rest/v3/edits.tracks
            if (info.immediateUpdateAllowed && info.updatePriority >= 4) {
                this.logger.warn('[APP] IMMEDIATE update', info.updatePriority);
                this.waitingForUpdate = true;
                await AppUpdate.performImmediateUpdate();
            } else if (info.flexibleUpdateAllowed) {
                this.logger.log('[APP] FLEXIBLE update starting');

                // prepare listener for updates
                AppUpdate.addListener('onFlexibleUpdateStateChange', (state: FlexibleUpdateState) => {
                    if (state.installStatus === FlexibleUpdateInstallStatus.DOWNLOADED) {
                        // NOTE: maybe ask user to restart first
                        this.logger.warn('[APP] FLEXIBLE update complete. Restarting as soon as sync completes');

                        this.syncSvc.syncRunning$
                            .pipe(
                                debounceTime(300),
                                takeWhile((status) => status !== false)
                            )
                            .subscribe({
                                complete: async () => {
                                    this.waitingForUpdate = false;
                                    this.logger.warn('[APP] sync stopped. now we can restart the app for native update');

                                    try {
                                        await AppUpdate.completeFlexibleUpdate();
                                    } catch (suEx) {
                                        this.logger.error(suEx);
                                        this.syncSvc.setSyncBlock(false);
                                        this.utilSvc.sentryCaptureException(suEx);
                                    }
                                },
                            });
                    } else if (
                        state.installStatus === FlexibleUpdateInstallStatus.FAILED ||
                        state.installStatus === FlexibleUpdateInstallStatus.CANCELED ||
                        state.installStatus === FlexibleUpdateInstallStatus.UNKNOWN
                    ) {
                        this.logger.warn('[APP] FLEXIBLE update failed with status', state.installStatus);
                        this.waitingForUpdate = false;
                    }
                });
                this.waitingForUpdate = true;
                let response = await AppUpdate.startFlexibleUpdate();
                if (response.code == AppUpdateResultCode.OK) {
                    // info to the suer
                    let toast = await this.toastCtrl.create({
                        header: 'Store Update verfügbar',
                        message: `Das Update wird im Hintergrund fertig heruntergeladen. Anschließend startet sich die App automatisch neu.`,
                        duration: 6000,
                        position: 'bottom',
                        cssClass: 'dp-toast',
                        buttons: [
                            {
                                side: 'end',
                                // icon: "close-outline",
                                text: 'OK',
                                role: 'cancel',
                                handler: () => {
                                    this.logger.log('[APP] Store Update toast dismissed');
                                },
                            },
                        ],
                    });

                    await toast.present();
                }
            } else {
                this.logger.log('[APP] linking to store update', 'android');
                await this.askForAppStoreOpen();
            }
        } else {
            this.logger.log('[APP] linking to store update', 'ios');
            await this.askForAppStoreOpen();
        }
    }

    /**
     * Helper to ask the user to open the platform's app store for updating the app
     * @private
     */
    private async askForAppStoreOpen() {
        const alert = await this.alertCtrl.create({
            header: 'Update verfügbar',
            message:
                'Ein neues Update ist im App Store verfügbar und ist empfohlen zu installieren. Soll der App Store geöffnet werden um es zu installieren?',
            buttons: [
                {
                    text: 'Abbrechen',
                    role: 'cancel',
                    handler: () => {
                        this.logger.log('[APP] Open Store cancelled', Capacitor.getPlatform());
                    },
                },
                {
                    text: 'OK',
                    role: 'confirm',
                    handler: () => {
                        AppUpdate.openAppStore();
                    },
                },
            ],
        });
        await alert.present();
    }
}
